@use "source/theme" as *;

.root {
  background-color: $UHCblue;
  min-height: 116px;

  .footer {
    color: $white;
    font-size: $fontLabel;
    padding-bottom: 40px;
    padding-top: 40px;
    width: 100%;

    .top {
      @include flexRow($alignX: space-between);
      width: 100%;

      img {
        width: 120px;
        height: auto;
        margin-right: 80px;
        margin-bottom: 32px;
      }

      .links {
        @include flexRow();
        margin-bottom: 20px;
        max-width: 720px;
        width: 100%;

        .items {
          @include flexColumn();
          max-width: calc(100% / 3);
          width: 100%;
          @include breakpoint-down(sm) {
            max-width: 50%;
          }
        }

        a {
          color: $white;
          margin-bottom: 8px;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .follow {
        @include flexColumn();

        a,
        img {
          cursor: pointer;
          height: 20px;
          margin-right: 20px;
          width: auto;
          &:last-child {
            margin-right: 0;
          }
        }

        .icons {
          margin-top: 12px;
        }
      }
    }

    .bottom {
      @include flexRow($alignX: space-between);
      border-top: 1px solid $white;
      font-size: 12px;
      padding-top: 16px;
      width: 100%;
      @include breakpoint-down(sm) {
        flex-direction: column;
        img {
          margin-top: 12px;
        }
      }
    }
  }
}
