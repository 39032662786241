import React from "react";
import Select from "../Select";
import style from "./style.scss";
import cn from "classnames";

export default ({
  name,
  label,
  half,
  type = "text",
  options = [],
  children,
  required,
  className,
  ...rest
}) => (
  <div className={cn(style.input, className)}>
    <label htmlFor={name}>
      {label}
      {required && <span>&nbsp;*</span>}
    </label>
    {type === "select" ? (
      <Select values={options} {...rest} aria-required={required} />
    ) : (
      <input
        {...{ name, required }}
        {...rest}
        type={type || "text"}
        id={name}
        aria-required={required}
      >
        {children}
      </input>
    )}
  </div>
);
