@use "source/theme" as *;

.home {
  @include flexColumn();
  width: 100%;
}

.bannerText {
  background-color: $skyblue;

  .content {
    max-width: 852px;
    align-items: center;
    position: relative;
    text-align: center;
    white-space: pre-wrap;
  }

  .heading {
    margin-bottom: 20px;
    white-space: pre-wrap;
    @include breakpoint-down(md) {
      white-space: unset;
    }
  }

  svg {
    position: absolute;
    bottom: -44px;
    margin: 0 auto;
    right: 0;
    left: 0;
    height: 52px;
    transform: rotate(180deg);
  }
}

.benefitsContent {
  @include flexRow($alignX: space-between);
  margin-top: 40px;
  max-width: 1024px;
  @include breakpoint-down(sm) {
    justify-content: center;
  }
}

.thankyou {
  justify-content: center;
  text-align: center;

  .content {
    @include flexRow($wrap: nowrap, $alignY: center);
    padding: 100px 40px 80px;
    margin-top: 100px;

    .heading {
      width: 50%;
    }

    .landing {
      width: 200px;
      height: auto;
      margin-left: 40px;
    }
  }
}
