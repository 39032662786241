export default {
  banner: {
    title: "Here to help you navigate your health care options",
    image: {
      src: require("assets/banner.webp"),
      placeholder: require("assets/banner.webp"),
      width: 1440,
      height: 475,
      alt: "Virtual Care Banner",
    },
  },
  bannerText: {
    title:
      "Virtual Care Coordination advocates on your\nbehalf, to help you focus on what is important to you.",
    subtitle:
      "We help coordinate care between members of your care team, support accessing resources to address your needs, and help you navigate options for your health care. We also provide guidance and education for the whole family.\nWe're here to help when you need us.",
  },
  benefits: {
    title: "The benefits of Virtual Care Coordination",
    items: [
      {
        icon: "collab",
        title: "Coordinated and connected",
        subtitle:
          "We help coordinate your health needs, while keeping your network of doctors informed.",
      },
      {
        icon: "care",
        title: "A care plan as unique as you",
        subtitle:
          "We want to learn what’s important to you. Let us work together to establish a personalized care plan and help you achieve your goals.",
      },
      {
        icon: "phone",
        title: "Just a phone call away",
        subtitle:
          "Whether you have questions, need help finding resources or services, or navigating what can be a complex health care system, we are here to help.",
      },
    ],
  },
};
