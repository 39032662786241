@use "source/theme" as *;

.modal {
  @include flexColumn(center, center);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 6;
  top: 0;
  left: 0;

  .space {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.content_container {
  @include flexColumn($alignX: center);
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.content {
  @include flexColumn(center, center);
  background-color: $white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  height: auto;
  padding: 24px;
  position: relative;
  width: auto;
  z-index: 2;

  @media screen and (max-width: 699px) {
    height: auto;
    width: calc(100% - 24px);
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.4;
    cursor: pointer;
    padding-bottom: 2px;
    &:hover {
      opacity: 0.2;
    }
  }
}
