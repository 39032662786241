import care from "./care.png";
import collab from "./collaboration.png";
import phone from "./phone.png";
import landing from "./landing.png";
import twitter from "./twitter.png";
import facebook from "./facebook.svg";
import instagram from "./instagram.svg";
import linkedin from "./linkedin.svg";
import youtube from "./youtube.svg";
import x from "./x.svg";
import arrow from "./down-arrow.svg";

export default {
  care,
  collab,
  phone,
  landing,
  twitter,
  facebook,
  instagram,
  linkedin,
  youtube,
  x,
  arrow,
};
