@use "source/theme" as *;

.input {
  @include flexColumn();
  border-radius: 2px;
  font-size: $fontBody;
  margin-bottom: 20px;
  width: 100%;
  &.half {
    width: 48%;
    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }

  label {
    margin-bottom: 4px;
    color: $darkgray;
    span {
      color: red;
    }
  }

  input {
    border: 1px $border2 solid;
    border-radius: 4px;
    box-sizing: border-box;
    color: $darkgray;
    font-family: $fontFamily;
    font-size: $fontBody;
    padding: 8px;
    width: 100%;
    &:focus,
    &:active {
      border-color: $link;
      outline: none;
    }
  }
}
