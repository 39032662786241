import React from "react";
import Header from "molecule/Header";
import Footer from "molecule/Footer";
import Home from "./Home";

export default () => (
  <div
    style={{
      width: "100%",
      minHeight: "100vh",
      position: "relative",
    }}
  >
    <Header />
    <Home />
    <Footer />
  </div>
);
