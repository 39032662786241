import React, { useState } from "react";
import Icon from "atom/Icon";
import Modal from "atom/Modal";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import Banner from "molecule/Banner";
import IconText from "molecule/IconText";
import ContactUs from "./ContactUs";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";

export default () => {
  const [finishContact, setFinishContact] = useState(false);

  function reachOut() {
    window.location.href = "#reach-out";
  }

  return (
    <div className={cn(style.home)} role="main">
      <Banner {...data.banner} onClick={reachOut} />
      <PageCenter
        rootClass={cn(style.bannerText)}
        contentClass={cn(style.content)}
      >
        <Heading centered serif type={"h3"} className={cn(style.heading)}>
          {data.bannerText.title}
        </Heading>
        <p>{data.bannerText.subtitle}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="rgb(217, 246, 250)"
        >
          <polygon points="256 32 20 464 492 464 256 32" />
        </svg>
      </PageCenter>
      <PageCenter contentClass={cn(style.benefitsContent)}>
        <Heading centered serif type={"h2"} className={cn(style.heading)}>
          {data.benefits.title}
        </Heading>
        {data.benefits.items.map((value, i) => (
          <IconText {...value} key={i} />
        ))}
      </PageCenter>
      <ContactUs setFinishContact={() => setFinishContact(true)} />
      {finishContact && (
        <Modal
          show={finishContact}
          containerClass={cn(style.thankyou)}
          contentClass={cn(style.content)}
          close={() => setFinishContact(false)}
          id={"Contact"}
        >
          <Heading serif type={"h3"}>
            Thank you for contacting us!
            <br /> We'll be in touch very soon.
          </Heading>
          <Icon icon={"landing"} className={cn(style.landing)} />
        </Modal>
      )}
    </div>
  );
};
