@use "source/theme" as *;

.iconText {
  @include flexColumn(center, center);
  padding: 40px 8px;
  max-width: calc(100% / 3 - 8px);
  text-align: center;
  @include breakpoint-down(md) {
    max-width: calc(100% / 2 - 8px);
  }
  @include breakpoint-down(sm) {
    max-width: 340px;
    padding-bottom: 20px;
  }

  svg,
  img {
    margin-bottom: 20px;
    width: auto;
    height: 100px;
    @include breakpoint-down(sm) {
      height: 80px;
    }
  }

  p {
    margin-top: 8px;
  }
}
