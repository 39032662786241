import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({
  children,
  className,
  disabled,
  onClick,
  type = "button",
  dark = true,
  to,
  ...rest
}) => {
  // var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

  // if (mac) {
  //   document.getElementById("close").classList.add("left");
  // }

  return (
    <button
      className={cn(style.button, dark ? style.dark : style.light, className)}
      {...{ disabled, onClick, type }}
      {...rest}
    >
      {children}
    </button>
  );
};
