import React from "react";
import Icon from "../Icon";
import style from "./style.scss";
import cn from "classnames";

export default ({
  className,
  id,
  value = "DEFAULT",
  values = [],
  onChange,
  onFocus,
  textOption,
  ...rest
}) => (
  <div className={cn(style.container, className)}>
    <select name={id} {...{ id, value, onChange, onFocus }} {...rest}>
      <option value="" disabled>
        {textOption}
      </option>
      {values.map((value, i) => (
        <option key={value + i} value={value}>
          {value}
        </option>
      ))}
    </select>
    <Icon icon="arrow" />
  </div>
);
