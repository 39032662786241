import React from "react";
import iconEnum from "assets/icons";

const Icon = ({ icon, className, onClick, svg }) =>
  svg ? (
    <span {...{ className, onClick }}>
      {React.createElement(iconEnum[icon], {
        ["aria-labelledby"]: `${icon + Math.random()}`,
      })}
    </span>
  ) : (
    <img
      {...{ className, onClick }}
      src={iconEnum[icon]}
      alt={icon}
      aria-labelledby={icon + Math.random()}
    />
  );

export default Icon;
