import React from "react";
import style from "./style.scss";
import cn from "classnames";

const PageCenter = ({
  image,
  children,
  rootClass,
  contentClass,
  id,
  role,
  aria,
}) => (
  <div {...{ id }} className={cn(style.root, rootClass)}>
    {image && image}
    <div
      className={cn(style.content, contentClass)}
      role={role}
      aria-label={aria}
    >
      {children}
    </div>
  </div>
);

export default PageCenter;
