@use "source/theme" as *;

@mixin format($size: $h1-font-sizes) {
  @include font-size($size);
  color: $UHCblue;
  font-weight: 900;
}

.default {
  white-space: pre-wrap;
  width: 100%;
  @media screen and (max-width: 767px) {
    white-space: normal;
  }

  &.h1 {
    @include format();
    margin-bottom: 12px;
  }

  &.h2 {
    @include format($h2-font-sizes);
    margin-bottom: 12px;
  }

  &.h3 {
    @include format($h3-font-sizes);
  }

  &.h4 {
    @include format($h4-font-sizes);
  }
}

.serif {
  font-family: $fontFamilySerif;
}
