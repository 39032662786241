@font-face {
  font-family: "UHC Sans";
  font-weight: 700;
  font-display: swap;
  src: local("myfont"), url("../assets/UHCSans-Bold.woff2") format("woff");
}

@font-face {
  font-family: "UHC Sans";
  font-weight: 600;
  font-display: swap;
  src: local("myfont"), url("../assets/UHCSans-SemiBold.woff2") format("woff");
}

@font-face {
  font-family: "UHC Sans";
  font-weight: 500;
  font-display: swap;
  src: local("myfont"), url("../assets/UHCSans-Medium.woff2") format("woff");
}

@font-face {
  font-family: "UHC Serif";
  font-weight: 700;
  font-display: swap;
  src: local("myfont"),
    url("../assets/UHCSerifHeadline-Semibold.woff2") format("woff");
}

//App Shell
.app {
  background-color: white;
  display: flex;
  max-width: 100vw;
  cursor: default;
}

html {
  scroll-behavior: smooth;
}

//Body
body {
  color: var(--palette-dark-gray);
  margin: 0 auto;
  padding: 0;
  font-family: "UHC Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
  font-size: 16px;
  line-height: 25px;
  min-width: 320px;
}

//Global classes
h1,
h2,
h3,
h4,
ul,
p {
  margin: 0;
}

p {
  color: rgb(90, 90, 90);
}

a {
  text-decoration: none;
  cursor: default;

  &:focus-visible {
    outline: 1px rgb(90, 90, 90) dashed;
  }
}

img {
  flex-shrink: 0;
}
