export default {
  heading: "Let us take care of the complicated.",
  title:
    "You can reach us at 1-888-608-1857, TTY 711\nMonday through Friday from 7 a.m. to 7 p.m. CST",
  subtitle: "REACH OUT FOR MORE INFORMATION",
  fields: [
    { name: "firstName", label: "First name" },
    { name: "lastName", label: "Last name" },
    { name: "email", label: "Email", autoComplete: "email" },
    {
      name: "phone",
      label: "Phone number",
      type: "tel",
      minLength: 7,
      autoComplete: "tel",
    },
  ],
  disclaimer:
    "Plans are insured through UnitedHealthcare Insurance Company or one of its affiliated companies, a Medicare Advantage organization with a Medicare contract. Enrollment in the virtual care coordination plan depends on the plan’s contract renewal with Medicare.",
  disclaimer2:
    "In case of an emergency, call 9-1-1 or go to the nearest emergency room.",
};
