@use "source/theme" as *;

.container {
  @include flexRow(flex-start, center, nowrap);
  border: 1px $border2 solid;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  &:focus-within {
    border-color: $link;
    outline: none;
  }

  select {
    appearance: none;
    background-color: $white;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: $darkgray;
    cursor: pointer;
    font-family: $fontFamily;
    font-size: $fontBody;
    height: 40px;
    line-height: 25px;
    outline: none;
    padding: 8px;
    width: 100%;
  }

  img {
    fill: $darkgray;
    pointer-events: none;
    position: absolute;
    right: 8px;
    height: 40px;
    top: 0;
    width: 8px;
  }
}
