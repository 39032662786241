@use "source/theme" as *;

.contactUs {
  background-color: $lightgray;

  .content {
    align-items: center;
    max-width: 800px;
    white-space: pre-wrap;

    .title {
      margin-top: 20px;
    }
    .subtitle {
      color: $darkgray;
      margin: 52px 0 40px;
    }

    .disclaimer {
      font-size: $fontLabel;
      color: $darkgray;
      max-width: 726px;
      margin-top: 40px;

      label {
        font-weight: 700;
        line-height: 44px;
      }
    }
  }
}

.form {
  @include flexRow($alignX: space-between, $wrap: wrap);
  width: 100%;

  .input {
    width: calc(50% - 16px);
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }

  button {
    margin: 12px auto 0;
  }

  .error {
    font-size: $fontLabel;
    color: red;
    margin: -16px 0 20px;
    width: 100%;
  }
}
