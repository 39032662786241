import React from "react";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";
import Icon from "atom/Icon";

const IconText = ({ icon, title, subtitle, className, aria }) => (
  <div className={cn(style.iconText, className)} aria-label={aria}>
    <Icon {...{ icon }} />
    <Heading type={"h4"}>{title}</Heading>
    <p>{subtitle}</p>
  </div>
);

export default IconText;
