import React, { useEffect, useState } from "react";
import axios from "axios";
import Input from "atom/Input";
import Button from "atom/Button";
import { Heading } from "atom/Typography";
import data from "./data";
import style from "./style.scss";
import cn from "classnames";
import PageCenter from "atom/PageCenter";

export default ({ setFinishContact }) => {
  // const [formInput, setFormInput] = useState([]);
  // const [submitted, setSubmitted] = useState(false);
  // const [errorSelect, setErrorSelect] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setSubmitted(true);

  //   axios
  //     .post("/api/sendMail.php", formInput)
  //     .then((response) => {
  //       console.warn(response.data);
  //       if (response.data && response.data.success) {
  //         setFinishContact();
  //         setSubmitted(false);
  //         setFormInput([]);
  //         close();
  //       }
  //     })
  //     .catch(function (error) {
  //       console.warn("Request Error:", error);
  //       setSubmitted(false);
  //     });
  // };

  // const handleChange = (e, name) => {
  //   console.warn(
  //     name,
  //     !formInput["occupation"] || formInput["occupation"] == "undefined"
  //   );
  //   setFormInput((prevState) => ({
  //     ...prevState,
  //     [name]: e.target.value,
  //   }));
  //   if (name != "occupation" && !formInput["occupation"] && !errorSelect) {
  //     setErrorSelect(true);
  //   } else if (name == "occupation" && errorSelect) setErrorSelect(false);
  // };

  return (
    <PageCenter
      rootClass={cn(style.contactUs)}
      contentClass={cn(style.content)}
      id={"reach-out"}
    >
      {/* <Heading serif centered type={"h2"}>
        {data.heading}
      </Heading> */}
      <Heading serif centered type={"h2"} className={cn(style.title)}>
        {data.title}
      </Heading>
      {/* <Heading centered type={"h4"} className={cn(style.subtitle)}>
        {data.subtitle}
      </Heading>
      <form
        className={cn(style.form)}
        name={"ContactUsForm"}
        onSubmit={(e) => handleSubmit(e)}
      >
        {data.fields.map((value, i) => (
          <Input
            {...value}
            key={i}
            value={formInput[value.name] || ""}
            onChange={(e) => handleChange(e, value.name)}
            className={cn(style.input)}
            required
          />
        ))}
        <Input
          {...{
            name: "occupation",
            textOption: "Select one",
            label: "I am a(n)",
            type: "select",
            options: [
              "Caregiver",
              "Patient",
              "Provider",
              "Administrator",
              "Other",
            ],
          }}
          value={formInput["occupation"] || ""}
          onChange={(e) => handleChange(e, "occupation")}
          required
        />
        {errorSelect && (
          <p className={cn(style.error)}>{"Please select a role."}</p>
        )}
        <Button disabled={submitted || errorSelect} type={"submit"}>
          {"Submit"}
        </Button>
      </form> */}
      <div className={cn(style.disclaimer)}>
        <label>DISCLAIMER</label>
        <br />
        {data.disclaimer}
        <br />
        <label>{data.disclaimer2}</label>
      </div>
    </PageCenter>
  );
};
