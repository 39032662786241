@use "source/theme" as *;

a {
  color: $link;
  cursor: pointer;
  font-weight: 900;
  text-decoration: none;
  &:hover,
  &:focus-visible {
    color: $linkHover;
  }
}

.button {
  @include flexColumn(center, center);
  position: relative;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-family: $fontFamily;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-bottom: 8px;
  min-width: 100px;
  outline: none;
  padding: 10px 20px 8px;
  transition: all ease-in-out 0.1s;
  white-space: nowrap;

  &.dark {
    @include button("dark");
  }
  &.light {
    @include button("white");
  }

  &:disabled {
    background-color: $darkgray;
    cursor: default;
  }
}
