@use "source/theme" as *;

.root {
  background-color: $white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 16%);
  position: relative;
  z-index: 5;

  .content {
    padding-bottom: 0;
    padding-top: 0;
  }

  .header {
    @include flexRow($alignX: space-between, $alignY: center);
    min-height: 112px;
    width: 100%;
    transition: all 0.2s ease-in-out;
    @include breakpoint-down(md) {
      min-height: 72px;
    }

    .logo img {
      box-sizing: border-box;
      height: 64px;
      margin-left: 20px;
      transition: all 0.2s ease-in-out;
      @include breakpoint-down(md) {
        height: 36px;
        margin-left: 0;
      }
    }

    button {
      @include breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
}
