import React from "react";
import PageCenter from "atom/PageCenter";
import ProgressiveImage from "atom/ProgressiveImage";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const Banner = ({ image, title }) => (
  <PageCenter rootClass={cn(style.root)} contentClass={cn(style.container)}>
    <ProgressiveImage {...image} />
    <div className={cn(style.fullText)}>
      <div className={cn(style.text)}>
        <Heading serif>{title}</Heading>
      </div>
    </div>
  </PageCenter>
);

export default Banner;
