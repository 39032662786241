import React from "react";
import scss from "./style.scss";
import cn from "classnames";

export const Heading = ({
  uppercase = false,
  className,
  weight,
  centered = false,
  serif,
  children,
  style,
  type = "h1",
}) => {
  return (
    <>
      {React.createElement(
        type == "h1" ? "h1" : "div",
        {
          className: `${cn(
            scss.default,
            type === "h2"
              ? scss.h2
              : type === "h3"
              ? scss.h3
              : type === "h4"
              ? scss.h4
              : scss.h1,
            serif && scss.serif,
            className
          )}`,
          style: {
            fontWeight: weight,
            textAlign: centered && "center",
            textTransform: uppercase && "uppercase",
            ...style,
          },
        },
        children
      )}
    </>
  );
};
