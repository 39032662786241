@use "source/theme" as *;

.root {
  @include flexColumn(center, center);
  width: 100%;
}

.content {
  @include flexColumn();
  margin: 0 auto;
  max-width: 1286px;
  padding: 48px 16px;
  width: 100%;
}
