import React from "react";
import Anchor from "atom/Anchor";
import PageCenter from "atom/PageCenter";
import UHCMonogram from "assets/uhc_monogram.svg";
import UHCStacked from "assets/uhc_stacked.svg";
import style from "./style.scss";
import cn from "classnames";
import Icon from "atom/Icon";

const main = "© 2023 United HealthCare Services, Inc. All rights reserved.";
const links = [
  [
    { label: "Contact us", url: "https://www.uhc.com/contact-us" },
    { label: "Careers", url: "https://www.uhc.com/about-us/careers" },
    { label: "About us", url: "https://www.uhc.com/about-us" },
    { label: "Accessibility", url: "https://www.uhc.com/legal/accessibility" },
  ],
  [
    { label: "Privacy", url: "https://www.uhc.com/privacy" },
    { label: "Terms of use", url: "https://www.uhc.com/privacy/terms-of-use" },
    { label: "Legal", url: "https://www.uhc.com/legal" },
    {
      label: "Language assistance",
      url: "https://www.uhc.com/legal/nondiscrimination-and-language-assistance-notices",
    },
  ],
  [
    {
      label: "Nondiscrimination",
      url: "https://www.uhc.com/legal/nondiscrimination-and-language-assistance-notices",
    },
    { label: "Health care fraud", url: "https://www.uhc.com/fraud" },
  ],
];
const follow = "Follow us on";
const followLinks = [
  { icon: "facebook", url: "https://www.facebook.com/UnitedHealthcare" },
  { icon: "instagram", url: "https://www.instagram.com/unitedhealthcare/" },
  { icon: "twitter", url: "https://twitter.com/uhc" },
  { icon: "youtube", url: "https://www.youtube.com/UnitedHealthcare" },
  {
    icon: "linkedin",
    url: "https://www.linkedin.com/showcase/unitedhealthcare/",
  },
];

const Footer = () => (
  <PageCenter
    rootClass={cn(style.root)}
    contentClass={cn(style.footer)}
    role="contentinfo"
    aria={"United Healhtcare Footer"}
  >
    <div className={cn(style.top)}>
      <img
        src={UHCStacked}
        alt={"United Healthcare Logo"}
        aria-labelledby={"United Healthcare Logo"}
      />
      <div className={cn(style.links)}>
        {links.map((val, i) => (
          <div key={i} className={cn(style.items)}>
            {val.map(({ label, url }, d) => (
              <Anchor key={d} href={url} aria-label={`${label} link`}>
                {label}
              </Anchor>
            ))}
          </div>
        ))}
      </div>
      <div className={cn(style.follow)}>
        <strong>{follow}</strong>
        <div className={cn(style.icons)}>
          {followLinks.map((value, i) => (
            <Anchor key={i} href={value.url} aria-label={`${value.icon} link`}>
              <Icon icon={value.icon} />
            </Anchor>
          ))}
        </div>
      </div>
    </div>
    <div className={cn(style.bottom)}>
      {main}
      <img
        src={UHCMonogram}
        alt={"United Healthcare Monogram Logo"}
        aria-labelledby={"United Healthcare Monogram Logo"}
      />
    </div>
  </PageCenter>
);

export default Footer;
