import React from "react";
import PageCenter from "atom/PageCenter";
import UHCLogo from "assets/uhc.png";
import style from "./style.scss";
import cn from "classnames";
import Button from "atom/Button";

const Header = () => {
  const scroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <PageCenter
      rootClass={cn(style.root)}
      contentClass={cn(style.content)}
      role="banner"
    >
      <div className={cn(style.header)}>
        <div className={cn(style.logo)} onClick={() => scroll()}>
          <img
            src={UHCLogo}
            alt={"United Healthcare Icon Logo"}
            aria-labelledby={"United Healthcare Icon Logo"}
          />
        </div>
      </div>
    </PageCenter>
  );
};

export default Header;
