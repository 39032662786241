import React, { useEffect } from "react";
import Icon from "../Icon";
import style from "./style.scss";
import cn from "classnames";

export default ({
  show,
  close,
  children,
  className,
  containerClass,
  contentClass,
}) => {
  function disableScroll() {
    document.documentElement.style.setProperty("scroll-behavior", "unset");
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  function enableScroll() {
    window.onscroll = function () {};
  }

  useEffect(() => {
    if (show) disableScroll();
  }, [show]);

  useEffect(() => {
    return () => {
      document.documentElement.style.setProperty("scroll-behavior", "smooth");
      enableScroll();
    };
  }, []);

  const closeModal = () => {
    document.documentElement.style.setProperty("scroll-behavior", "smooth");
    enableScroll();
    close();
  };

  return (
    <div className={cn(style.modal, className)}>
      <div className={cn(style.space)} onClick={() => closeModal()} />
      <div className={cn(style.content_container, containerClass)}>
        <div className={cn(style.content, contentClass)}>
          {children}
          <Icon
            icon="x"
            className={cn(style.close)}
            onClick={() => closeModal()}
          />
        </div>
      </div>
    </div>
  );
};
