@use "source/theme" as *;

.root {
  position: relative;
}

.container {
  @include flexColumn();
  padding: 0;
  position: relative;
  max-width: unset;
  width: 100%;

  img {
    object-fit: cover;
    object-position: top;
    transition: all 0.2s ease-in-out;
    width: 100%;
    @include breakpoint-down(md) {
      height: 340px;
      object-position: 100%;
    }
    @include breakpoint-down(sm) {
      height: 252px;
    }
  }

  .fullText {
    @include flexColumn();
    left: 0;
    margin: 0 auto;
    max-width: 1286px;
    padding: 0 20px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    @include breakpoint-down(lg) {
      top: 40px;
    }
    @include breakpoint-down(md) {
      align-items: center;
      margin-top: -80px;
      position: static;
    }

    .text {
      @include flexColumn($alignY: center);
      margin: auto 0;
      max-width: 524px;
      padding: 40px;
      width: 100%;
      @include breakpoint-down(md) {
        background-color: rgba(white, 0.9);
        padding: 40px 20px;
      }

      button {
        margin-top: 12px;
      }
    }
  }
}
