import "./theme.scss";
import "theme/root.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import Screen from "./screen";
import style from "theme/root.scss";
import cn from "classnames";

const App = () => (
  <div className={cn(style.app)}>
    <Screen />
  </div>
);

const root = createRoot(document.getElementById("root"));
root.render(<App />);
